/* SOUNDFORM SVG ANIMATION */
@keyframes dash {
  from {
    stroke-dashoffset: 180;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.soundwave-container {
  padding: 16px;
  width: 100%;
}

.soundform-line {
  stroke-dasharray: 220;
  stroke-dashoffset: 200;
  animation-name: dash;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.soundform-line:nth-child(even) {
  animation-direction: alternate;
  animation-duration: .5s;
}

.soundform-line:nth-child(odd) {
  animation-direction: alternate-reverse;
  animation-duration: .8s;
}