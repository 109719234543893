/*FONT DECLARATIONS*/
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Bold.otf") format("opentype");
  font-weight: 200;
}
/**/

/*VIEW ANIMATIONS*/
.fade-enter {
  opacity: 0;
  z-index: 1;
  transition: opacity 400ms ease;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}
.fade-exit-done {
  opacity: 0;
}
/**/

/*LEAFLET*/
.leaflet-control-attribution {
  opacity: 0;
}
/**/

/*LOCK*/
.lock.day .react-pattern-lock__point-inner {
  background: rgba(0, 0, 0, 0.4);
}
.lock.day .react-pattern-lock__connector {
  background: rgba(0, 0, 0, 0.2);
}
.lock.day
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__point-inner {
  background: rgba(0, 0, 0, 0.7);
}
.lock.day
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__connector {
  background: rgba(0, 0, 0, 0.6);
}

.lock.night .react-pattern-lock__point-inner {
  background: rgba(255, 255, 255, 0.4);
}
.lock.night .react-pattern-lock__connector {
  background: rgba(255, 255, 255, 0.2);
}
.lock.night
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__point-inner {
  background: rgba(255, 255, 255, 0.8);
}
.lock.night
  .react-pattern-lock__pattern-wrapper.success
  .react-pattern-lock__connector {
  background: rgba(255, 255, 255, 0.6);
}

.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__point-inner,
.react-pattern-lock__pattern-wrapper.error .react-pattern-lock__connector {
  background: #940000 !important;
}

.demo-lock {
  transform: scale(0.8);
  margin: -40px 0px;
}
.react-pattern-lock__point-inner.active:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="0"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="3"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="6"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="7"]
  .react-pattern-lock__point-inner:before,
.demo-lock
  .react-pattern-lock__point-wrapper[data-index="8"]
  .react-pattern-lock__point-inner:before {
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  border: 2px solid #32fd43;
  border-radius: 50%;
  position: absolute;
  margin-left: -5px;
  margin-top: -5px;
}

/* prevent pull-down-to-refresh of mobile chrome */
body {
  overscroll-behavior-y: contain;
}
